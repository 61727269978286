import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { ToastService } from '@Core/services/toast.service';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      const permission = route.data.permission;
      const action = route.data.action;
      if (permission && action) {
        const hasPermission = this.authService.hasPermission(permission, action);

        if (hasPermission === undefined) {
          this.router.navigate(['/']);
        } else if (!hasPermission) {
          this.toastService.showError('Error', 'Acceso denegado');
          this.router.navigate(['/']);
        }
        return hasPermission;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return false;
  }
}
