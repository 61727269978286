export enum AuthPermission {
    Catalog = 1,
    User = 2,
    CallRegister = 3,
    PredictiveMarc = 4,
    AssignedAgents = 5,
    DashboardBot = 6,
    Configuration = 7
}

export enum AuthAction {
    View = 'is_visible',
    Add = 'is_add',
    Edit = 'is_edit',
    Delete = 'is_delete'
}
