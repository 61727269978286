// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appVersion: 'v716demo1',
  USERDATA_KEY: 'authf649fc9a5f55',
  isMockEnabled: true,
  apiVersion: 'v0.1',
  apiUrl: 'https://ws.aconnectgpv.com/api/admin',
  apiContact: 'https://ws.aconnectgpv.com/api/contact',
  apiConnect: 'https://69njcz76nj.execute-api.us-east-1.amazonaws.com/prod',
  // InstanceId: 'cef6aebe-608b-483f-b440-a6a4caf7d847',
  InstanceId: '5186cc94-b807-4238-b9ba-d69ce51320cd',
  recoveryUser: 'contactCenter',
  recoveryPass: '123456',
  AWS_API_KEY: '9XnAvLYA6SaY3SiGciz251Zr1FiPs6m4ZhODQfPd',
  USER_ADMIN: 74,
  ROL_ADMIN: 30
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
