import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

const options = {
    timeOut: 300,
    positionClass: 'toast-top-center'
};

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(
        private toastr: ToastrService
    ) {}

    /**
     * Muestra un mensaje/notificación sin personalizar.
     * @param title Título del mensaje/notificación.
     * @param message Contenido del mensaje/notificación.
     */
    showSuccess(title, message) {
        this.toastr.success(message, title);
    }

    showWarning(title, message) {
        this.toastr.warning(message, title);
    }

    /**
     * Muestra un mensaje/notificación sin personalizar.
     * @param title Título del mensaje/notificación.
     * @param message Contenido del mensaje/notificación.
     */
    showError(title, message) {
        this.toastr.error(message, title);
    }

}
