import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

const API_USERS_URL = `${environment.apiUrl}/${environment.apiVersion}`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    constructor(private http: HttpClient) {}

    // public methods
    login(email: string, password: string): Observable<any> {
        return this.http.post<AuthModel>(`${API_USERS_URL}/login/${email}/${password}`, {});
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: UserModel): Observable<UserModel> {
        return this.http.post<UserModel>(API_USERS_URL, user);
    }

    // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
    forgotPassword(email: string): Observable<boolean> {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Basic ${ btoa(`${ environment.recoveryUser }:${ environment.recoveryPass }`) }`,
        });
        return this.http.post<boolean>(`${API_USERS_URL}/recovery/${email}`, {}, { headers: httpOptions });
    }

    changePassword(token: string, password: string): Observable<boolean> {
        const httpOptions = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Basic ${ btoa(`${ environment.recoveryUser }:${ environment.recoveryPass }`) }`,
        });
        return this.http.put<boolean>(`${API_USERS_URL}/recovery`, { token, password}, { headers: httpOptions });
    }


    getRoles(id: number, token): Observable<any> {
        const headers = new HttpHeaders({ Authorization: `Bearer ${token.token}` });
        return this.http.get(`${API_USERS_URL}/role/${id}`, { headers });
    }

    getUserByToken(token): Observable<UserModel> {
        const httpHeaders = new HttpHeaders({
            Authorization: `Bearer ${token}`,
        });
        return this.http.get<UserModel>(`${API_USERS_URL}`, {
            headers: httpHeaders,
        });
    }
}
