import { Injectable } from '@angular/core';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HTTP_INTERCEPTORS, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ToastService } from '@Core/services/toast.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private loader: NgxUiLoaderService, private toastService: ToastService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loader.start();

        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse || HttpErrorResponse) {
                    this.loader.stop();
                }
            }),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    const serverError = this.getErrorString(error.error);
                    this.toastService.showError('Error', serverError);
                    return throwError(serverError);
                }
            })
        );
    }

    getErrorString(error): string {
        const type = error.type;
        switch (type) {
            case 0:
                return 'Usuario y/o contraseña incorrecto.';
            case -1:
                return 'Nombre de usuario duplicado.';
            case -2:
                return 'Email duplicado.';
            case -3:
                return  'Ocurrió un error al procesar la petición.'; //'No se puede editar el rol, ya que se encuentra asignado a uno o más usuarios activos.';
            case -4:
                return 'No es posible eliminar el rol, ya que se encuentra ligado a usuarios en el sistema o ya no existe, para eliminar este rol no debe estar asignado a ningún usuario existente.';
            default:
                return 'Ocurrió un error al procesar la petición.';
        }
    }
}

export const ErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
};
