import { Injectable } from '@angular/core';

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';

import { Observable } from 'rxjs';
import { SessionService } from '@Modules/auth/_services/session.service';
import { environment } from '@Environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    API_CONNECT: string = environment.apiConnect;

    constructor(private sessionService: SessionService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.sessionService.getAuthFromLocalStorage();
        const token: string = auth?.token;
        const isApiUrl = request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.apiContact);

        if (token && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                },
            });
        } else if (this.API_CONNECT) {
            request = request.clone({
                setHeaders: {
                    'x-api-key': environment.AWS_API_KEY,
                },
            });
        }

        return next.handle(request);
    }
}

export const TokenInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
};
