import { Injectable, OnDestroy } from '@angular/core';
import { AuthModel } from '../_models/auth.model';
import { environment } from '@Environments/environment';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    constructor() {}

    public getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken));
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }
}
