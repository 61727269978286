import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, forkJoin } from 'rxjs';
import { map, catchError, switchMap, finalize, mergeMap } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http/auth-http.service';
import { environment } from '@Environments/environment';
import { Router } from '@angular/router';
import { AuthAction, AuthPermission } from '@Core/enums/auth';
import { NavigationModules } from '@Metronic/configs/navigation-titles.config';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;


  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<UserModel> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      mergeMap(response => {
        const token = response.token;
        const roleId = response.user.role_id;
        return forkJoin([of(response), this.authHttpService.getRoles(roleId, token)]);
      }),
      map((response: any) => {
        const authData = response[0];
        const roleData = response[1];
        const mappedAuth = new AuthModel();
        mappedAuth.user = authData.user;
        mappedAuth.user.roles = roleData;
        mappedAuth.setAuth(authData.token);
        const result = this.setAuthFromLocalStorage(mappedAuth);
        this.currentUserSubject = new BehaviorSubject<UserModel>(mappedAuth.user);
        return result;
      }),
      // switchMap(() => this.getUserByToken()),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserModel> {
    const auth = this.getAuthFromLocalStorage();
    const user = auth?.user;
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);

    if (user) {
      this.currentUserSubject = new BehaviorSubject<UserModel>(user);
      return of(user);
    } else {
      this.logout();
    }

    this.isLoadingSubject.next(false);
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  changePassword(token: string, password: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .changePassword(token, password)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  public hasPermission(permission: AuthPermission, action: AuthAction): boolean {
    const authData = this.getAuthFromLocalStorage();
    if (!authData) { return; }
    const roles = authData.user.roles;
    return this.isActionAllowed(roles, permission, action);
  }

  public async checkPermissionsByUserSubject(user: Observable<UserModel>, modules) {
    await user.subscribe(result => {
      const roles = result.roles;
      for (const key in modules) {
        if (modules[key]) {
          modules[key] = this.isActionAllowed(roles, AuthPermission[key], AuthAction.View);
        }
      }

      const firstModuleAllowed = Object.entries(modules).find(m => m[1]);
      if (firstModuleAllowed) {
        const moduleName = firstModuleAllowed[0];
        // console.log(moduleName);
        // this.router.navigate([NavigationModules[moduleName]]);
      }
    });
  }

  private isActionAllowed(roles, permission, action): boolean {
    const roleFound: any = roles.find((r: any) => r.permissions.find(p => p.permission_id === permission));
    if (!roleFound) {
      return false;
    }
    const permissionFound = roleFound.permissions.find(p => p.permission_id === permission);
    return permissionFound[action] === 1;
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
